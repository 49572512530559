.bg-gl {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}
.footer-bg-gl {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.btn {
  display: block;
  padding: 10px 20px;
  color: white;
  border: 1px solid white;
  text-decoration: none;
  font-weight: 700;
  transition: 0.15s;
}

.btn:hover {
  background-color: white;
}

.btn.purple:hover {
  background-color: #4C19AF;
}

.container {
  max-width: 1320px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.container.wide {
  max-width: 90%;
}
.container.tall {
  margin-top: 50vh;
}
.container.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

select {
  color: white;
  padding: 10px 20px;
  background-color: transparent;
  border: 1px solid rgba(172, 255, 47, 0.597);
  border-radius: 10px;
}

.navbar_container {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 30px 30px;
  z-index: 20;
  background: linear-gradient(0, transparent, #00000090);
}

.navbar_container nav {
  display: flex; 
  justify-content: space-between;
  align-items: center;
}

nav .logo_wrapper {
  max-width: 150px;
}

section.header_section {
  margin-bottom: 30vh;
}

.video_container {
    position: absolute;
    width: 65vh;
    max-width: 70vw;
    height: 65vh;
    max-height: 70vw;
    margin: auto;
    left: 0;
    right: 0;
    top: -15px;
    bottom: 0;
    perspective: 1000px;
    z-index: 3;
}

.video_container video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 30px;
    transition: 0.15s;
    filter: brightness(50%);
}

.title_container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    margin-top: -30px;
}
.title {
	top: 10px;
	z-index: 10;
    font-size: 148px;
    font-weight: 800;
    color: white;
    line-height: 1;
}
.title_text_wrapper {
  overflow: hidden;
}
#dynamic-title {
    min-height: 1em;
}
#dynamic-title::after {
    content: '';
    display: inline-block;
    position: absolute;
    margin-left: 6px;
    border-right: 8px solid white;
    height: 1em;
    animation: 0.9s ticker infinite linear;

}

.gradient_wrapper {
  --size: 900px;
  position: fixed;
  width: var(--size);
  height: var(--size);
  transition: transform 0.15s;
  transform: translate3d(calc(50vw - (var(--size)/2)), calc(50vh - (var(--size)/2)), 0);
  opacity: 0;
  z-index: -1;
}
.gradient {
  --size: 900px;
  --speed: 7s;
  --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);

  width: var(--size);
  height: var(--size);
  filter: blur(calc(var(--size) / 5));
  background-image: linear-gradient(rgba(57, 196, 99, 0.85), #4C19AF);
  animation: rotate var(--speed) var(--easing) infinite;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
}

section {
  position: relative;
}

.spaced_title {
  text-align: center;
  line-height: 1.1;
  margin-bottom: 30vh;
  opacity: 0;
}

.langswitch {
  cursor: pointer;
  border: 1px solid white;
  transition: 0.2s;
}

/* .langswitch:hover {
  background-color: #39C463;
} */

.langswitch > .lang_element {
  display: inline-block;
  padding: 10px;
  font-weight: 700;
  font-size: 24px;
}

.langswitch > .lang_element.active {
  background-color: #39C463;
}

.langswitch > .lang_element:not(:last-child) {
  padding-right: 10px;
  border-right: 1px solid white;
}

.transition-circle {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  clip-path: circle(0% at 100% 0%);
  z-index: 100;
}

.fullscreen_ribbon {
  position: absolute;
  padding: 5px 20px;
  font-size: 36px;
  white-space: nowrap;
  left: 0;
  right: 0;
  margin: auto;
  min-width: 150%;
  /* left: -20%; */
  background-color: #39C463;
  z-index: 0;
}

.fullscreen_ribbon.reverse {
  
}

.fullscreen_ribbon span {
  margin-left: 10px;
  margin-right: 10px;
  padding-right: 20px;
  border-right: 3px solid white;
}

.fullscreen_ribbon span.bold {
  font-weight: 700;
}

.brief_container {
  display: flex;
  gap: 50px;
  padding-bottom: 10vh;
}

.brief_container .brief_text {
  flex-basis: 55%;
}

.brief_container .brief_other_container {
  flex-basis: 50%;
}

.brief_container .brief_text h2 {
  margin-bottom: 20px;
  text-align: left;
  max-width: 600px;
}

.brief_container .brief_stat {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 25px;
  column-gap: 50px;
  margin-top: 1.5rem;
}

.brief_container .brief_gallery {
  position: relative;
  margin-top: 3rem;
}

.brief_container .brief_gallery img {
  width: 50%;
  position: absolute;
}

.brief_container .brief_gallery img:nth-child(1) {
  right: 25px;
}

.brief_container .brief_gallery img:nth-child(2) {
  left: 25px;
  top: 35px;
}

.gallery_container {
  display: flex;
  gap: 10px;
}

.gallery_container .gallery_column {
  flex-basis: 25%;
}

.gallery_container .gallery_column img {
  margin-bottom: 8px;
}

section.process_section {
}

.process_section {
  position: relative;
}

.process_cursor {
  position: fixed;
  top: 0;
  left: 0;
  width: 12rem;
  height: 12rem;
  pointer-events: none;
  opacity: 0;
}

.cursor_media {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.5s;
}

.cursor_media.active {
  opacity: 0.8;
}

.process_container {
  width: 400%;
  display: flex;
  flex-wrap: nowrap;
}

.process_container .process_panel {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.process_panel .process_img {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 600px;
  border-radius: 30px;
  filter: brightness(0.6)
}
.process_panel p {
  /* position: absolute; */
  max-width: 800px;
  font-size: 32px;
  line-height: 1.2;
  margin-top: 20px;
}
.process_arrow {
  position: absolute;
  right: 20px;
  font-size: 102px;
}
.process_scroll_helper {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 50px;
  margin: auto;
  display: none;
}

.events_container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.event_item {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  gap: 20px;
}

.event_item.disabled {
  opacity: 0.5;
}

.event_item span {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.event_date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;
  background-color: #4C19AF;
  border-radius: 4px;
  border: 1px solid grey;
  padding: 5px;
}

.event_action {
  display: flex;
  justify-content: center;
  align-items: center;
}

.event_date-day {
  font-size: 48px;
  font-weight: 700;
  line-height: 0.9;
}
.event_date-month {
  font-size: 28px;
}
.event_date-year {
  font-size: 22px;
  line-height: 1.3;
}
.event_date-month {
  font-size: 28px;
}

.footer_container_wrapper {
  /* background-color: #041008; */
  min-height: 90vh;
  /* padding: 100px 0; */
  clip-path: ellipse(300% 55% at 48% 56%);
  position: relative;
}
.footer_container_wrapper .container {
  
}
.footer_content {
  padding-top: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.footer_content h3 {
  /* font-size: 92px; */
}

.footer_content .social_list {
  list-style: none;
  margin-top: 50px;
}
.footer_content .social_list li {
  margin-bottom: 15px;
}
.footer_content .social_list svg {
  margin-right: 10px;
  margin-bottom: -8px;
}
.footer_content .social_list a {
  color: white;
  text-decoration: none;
  font-size: 28px;
  transition: 0.2s;
  border-bottom: 2px solid transparent;
}
.footer_content .social_list a:hover {
  border-bottom: 2px solid white;
}


@keyframes ticker {
    0% {
        border-right: 8px solid white;
    }
    40% {
        border-right: 8px solid white;
    }
    51% {
        border-right: 8px solid transparent;
    }
    100% {
        border-right: 8px solid transparent;
    }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media screen and (max-width: 1599px) {
  .title {
    font-size: 132px;
  }
  h2 {
    font-size: 90px;
  }
}

@media screen and (max-width: 1399px) {
  .title {
    font-size: 120px;
  }
  h2 {
    font-size: 80px;
  }
  h3 {
    font-size: 60px;
  }
  p {
    font-size: 22px;
  }
}

@media screen and (max-width: 1199px) {
  .container {
    max-width: 960px;
  }
  .title {
    font-size: 96px;
  }
  h2 {
    font-size: 72px;
  }
  p {
    font-size: 20px;
  }
}

@media screen and (max-width: 991px) {
  .container {
    max-width: 720px;
  }
  .title {
    font-size: 82px;
  }
  h2 {
    font-size: 64px;
  }
  h3 {
    font-size: 52px;
  }
  p {
    font-size: 16px;
  }
  .process_grid > h2 {
    font-size: 54px;
  }
  .process_grid > p {
    font-size: 24px;
    max-width: 400px;
  }
  /* .gradient_wrapper {
    display: none;
  } */
  .gradient {
    --size: 500px;
  }
  .fullscreen_ribbon {
    padding: 4px 18px;
    font-size: 30px;
  }
  .footer-bg-gl {
    display: none;
  }
  .footer_content {
    grid-template-columns: auto;
    height: 90vh;
  }
}

@media screen and (max-width: 767px) {
  .title {
    font-size: 62px;
  }
  h2 {
    font-size: 60px;
  }
  h2.fixed_heading {
    font-size: 52px;
  }
  h4 {
    font-size: 36px;
  }
  h5 {
    font-size: 24px;
  }
  .brief_container .brief_stat {
    display: inline-grid;
    row-gap: 15px;
    column-gap: 30px;
    /* max-width: 400px; */
  }
  .brief_container {
    flex-direction: column;
    gap: 30px;
  }
  .brief_container.reverse {
    flex-direction: column-reverse;
  }
  .brief_other_container {
    min-height: 290px;
  }
  .process_grid {
    padding: 0 50px;
  }
  .process_grid > h2 {
    font-size: 52px;
  }
  .process_grid > p {
    font-size: 20px;
    max-width: 100%;
  }
  .process_arrow {
    right: 10px;
  }
  .fullscreen_ribbon {
    font-size: 24px;
  }
  .process_cursor, .process_arrow {
    display: none;
  }
  .event_item {
    grid-template-columns: auto auto;
  }
  .event_date {
    background-color: transparent;
  }
  .event_action {
    grid-column: 1 / 3;
  }
  .footer_content .social_list a {
    font-size: 24px;
  }
  .footer_content .social_list svg {
    width: 35px;
    height: 35px;
  }
  .credits {
    margin-top: 100px;
  }
  .process_scroll_helper {
    display: block;
  }
}

@media screen and (max-width: 575px) {
  .title {
    font-size: 48px;
  }
  h3 {
    font-size: 40px;
  }
  .process_grid {
    padding: 0 30px;
  }
  .process_grid > h2 {
    font-size: 42px;
  }
  .process_grid > p {
    font-size: 18px;
  }
  .fullscreen_ribbon {
    font-size: 14px;
  }
  .fullscreen_ribbon span {
    margin-left: 6px;
    margin-right: 6px;
    padding-right: 12px;
  }
}

@media screen and (max-width: 480px) {
  .title {
    font-size: 38px;
  }
  h3 {
    font-size: 40px;
  }
  .video_container {
    max-width: 75vw;
    max-height: 75vw;
  }
}

@media screen and (max-width: 360px) {
  .title {
    font-size: 32px;
  }
  .langswitch {
    display: none;
  }
  h2 {
    font-size: 48px;
  }
  h3 {
    font-size: 32px;
  }
  h4 {
    font-size: 28px;
  }
}

@media screen and (max-width: 320px) {
  .title {
    font-size: 26px;
  }
}
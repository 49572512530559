@import url('https://fonts.cdnfonts.com/css/helvetica-neue-5');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  color: white;
  overscroll-behavior: none;
}

.app {
  overflow: hidden;
  position: relative;
}

img {
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

hr {
  border: none;
  border-bottom: 2px solid #8298AB;
}

h1, h2, h3, h4, h5 {
  letter-spacing: -1px;
  font-weight: 700;
  line-height: 0.95;
}

h2 {
  font-size: 100px;
}

h3 {
  font-size: 72px;
}

h4 {
  font-size: 48px;
}

h5 {
  font-size: 32px;
}

p {
  font-size: 20px;
  line-height: 1.4;
}